<template>
<div class="ban-save" id="ban-save">
  <div class="service">
    <div class="main">
      <div class="body">
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane label="竞拍流程" name="1">
            <div class="bidding-tabs w-100">
                <el-radio-group v-model="biddingFlag" class="radio-group w-100">
                  <el-radio-button label='1' class="radio-btn">拍前准备</el-radio-button>
                  <el-radio-button label='2' class="radio-btn">报名参拍</el-radio-button>
                  <el-radio-button label='3' class="radio-btn">出价竞拍</el-radio-button>
                  <el-radio-button label='4' class="radio-btn">竞拍成功</el-radio-button>
                  <el-radio-button label='5' class="radio-btn">办理交割</el-radio-button>
                </el-radio-group>
              </div>
              <div v-if="biddingFlag =='1'">
                  <el-collapse v-model="activeNames11">
                      <el-collapse-item title="会员注册" name="1">
                          <p v-html="dataForm.vipRegistration" class="ql-editor" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                      <el-collapse-item title="阅读公告" name="2">
                          <p v-html="dataForm.announcement" class="ql-editor"  style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                      <el-collapse-item title="现场踏勘" name="3">
                          <p v-html="dataForm.exploration" class="ql-editor"  style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
              <div v-if="biddingFlag =='2'">
                  <el-collapse v-model="activeNames12">
                      <el-collapse-item title="报名交保证金" name="1">
                          <p v-html="dataForm.deposit" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
              <div v-if="biddingFlag =='3'">
                  <el-collapse v-model="activeNames13">
                      <el-collapse-item title="出价竞拍" name="1">
                          <p v-html="dataForm.auction" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
              <div v-if="biddingFlag =='4'">
                  <el-collapse v-model="activeNames14">
                      <el-collapse-item title="竞拍成功" name="1">
                          <p v-html="dataForm.auctionSuccessful" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
              <div v-if="biddingFlag =='5'">
                  <el-collapse v-model="activeNames15">
                      <el-collapse-item title="合同签订" name="1">
                          <p v-html="dataForm.contract" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                      <el-collapse-item title="支付货款" name="2">
                          <p v-html="dataForm.payment" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                      <el-collapse-item title="线下交割" name="3">
                          <p v-html="dataForm.delivery" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
          </el-tab-pane>
          <el-tab-pane label="交易规则" name="2">
          <div class="bidding-tabs w-100">
                <el-radio-group v-model="biddingFlag2" class="radio-group w-100">
                  <el-radio-button label='1' class="radio-btn">转让操作规则</el-radio-button>
                  <el-radio-button label='2' class="radio-btn">网络竞价实施办法</el-radio-button>
                  <el-radio-button label='3' class="radio-btn">交易保证金规则</el-radio-button>
                  <el-radio-button label='4' class="radio-btn">投资人诚信</el-radio-button>
                  <el-radio-button label='5' class="radio-btn">纠纷处理办法</el-radio-button>
                </el-radio-group>
              </div>
              <div v-if="biddingFlag2 =='1'">
                  <el-collapse v-model="activeNames21">
                      <el-collapse-item title="操作规则" name="1">
                          <p v-html="dataForm2.one" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
              <div v-if="biddingFlag2 =='2'">
                  <el-collapse v-model="activeNames22">
                      <el-collapse-item title="竞价实施" name="1">
                          <p v-html="dataForm2.two" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
              <div v-if="biddingFlag2 =='3'">
                  <el-collapse v-model="activeNames23">
                      <el-collapse-item title="交易保证金规则" name="1">
                          <p v-html="dataForm2.three" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>

                  </el-collapse>
              </div>
              <div v-if="biddingFlag2 =='4'">
                  <el-collapse v-model="activeNames24">
                      <el-collapse-item title="诚信管理" name="1">
                          <p v-html="dataForm2.five" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
              <div v-if="biddingFlag2 =='5'">
                  <el-collapse v-model="activeNames25">
                      <el-collapse-item title="纠纷处理" name="1">
                          <p v-html="dataForm2.six" style="height:100%;overflow-x: hidden;"></p>
                      </el-collapse-item>
                  </el-collapse>
              </div>
          </el-tab-pane>
          <el-tab-pane label="常见问题" name="8">
            <div>
                <el-collapse v-model="activeNames31">
                    <el-collapse-item title="常见问题" name="1">
                        <p v-html="dataForm2.seven" style="height:100%;overflow-x: hidden;"></p>
                    </el-collapse-item>
                </el-collapse>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    dataForm: {
        id: '1492085552608333826',
        vipRegistration: '',
        announcement: '',
        exploration: '',
        deposit: '',
        auction: '',
        auctionSuccessful: '',
        contract: '',
        payment: '',
        delivery: ''
        },
      dataForm2: {
        id: '1492085552608333827',
        one: '',
        two: '',
        three: '',
        four: '',
        five: '',
        six: '',
        seven: '',
        eight: '',
        nine: '',
      },
        activeNames11: ['1','2','3'],
        activeNames12: '1',
        activeNames13: '1',
        activeNames14: '1',
        activeNames15: ['1','2','3'],
        activeNames21: '1',
        activeNames22: '1',
        activeNames23: ['1','2','3'],
        activeNames24: '1',
        activeNames25: '1',
        activeNames31: '1',
        //拍前准备:会员注册
        vipRegistration1: "会员注册",
        //拍前准备:阅读公告
        announcement1: "阅读公告",
        //拍前准备:现场勘探
        exploration1: "现场勘探",
        //报名参拍:报名交保证金
        deposit:"报名交保证金",
        //出价竞拍：出价竞拍
        auction:"出价竞拍",
        //竞拍成功：竞拍成功
        auctionSuccessful:"竞拍成功",
        //办理交割：合同签订
        contract:"合同签订",
        //办理交割：支付货款
        payment:"支付货款",
        //办理交割：线下交割
        delivery:"线下交割",
        //转让操作规则：操作规则
        rule:"操作规则",
        // 网络竞价实施办法：竞价实施
        implementation:"竞价实施",
        // 交易保证金规则：会员注册
        vipRegistration2: "会员注册",
        // 交易保证金规则：阅读公告
        announcement2:"阅读公告",
        // 交易保证金规则：现场勘探
        exploration2: "现场勘探",
        // 投资人诚信：诚信管理
        sincerity:"诚信管理",
        // 纠纷处理办法：纠纷处理
        dispute:"纠纷处理",
        // 常见问题：常见问题
        problem:"常见问题",
      activeName: '1',
      sales: {
        'background-color': '',
      },
      procurement: {
        'background-color': '',
      },
      salesStyle: {
        'color': '#fdb913',
      },
      procurementStyle: {
        'color': '#f37321',
      },
      salesFont: {
        // 'color':'white',
      },
      procurementFont: {
        // 'color':'white',
      },
      accountFlag: '1',
      biddingFlag: '1',
      biddingFlag2: '1',
      biddingFlagbzj: '1',
      customerFlag: '1',
    }
  },
  mounted() {
    if(this.$route.query.id)
    this.biddingFlag = this.$route.query.id;
    if(this.$route.query.ids)
    this.biddingFlag2 = this.$route.query.ids;
     if(this.$route.query.idb)
    this.activeName = (this.$route.query.idb).toString();
  },
  activated(){
    this.getInfo1()
    this.getInfo2()
    this.getDetails();
  },
  methods: {
        // 获取信息
    getInfo1 () {
      this.$post('/shoppingui/ecbiddingprocess/' + this.dataForm.id).then(res => {

        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }

      }).catch(() => {})
    },
    getInfo2 () {
      this.$post('/shoppingui/ecbiddingprocess2/' + this.dataForm2.id).then(res => {

        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm2 = {
          ...this.dataForm2,
          ...res.data
        }

      }).catch(() => {})
    },
    moveChange() {
      this.sales = {
        'background-color': '#fdb913',
      };
      this.procurement = {
        'background-color': '#f37321',
      };
      this.salesStyle = {
        'color': '#FFFFFF',
      };
      this.procurementStyle = {
        'color': '#FFFFFF',
      };
      this.salesFont = {
        'color': '#FFFFFF',
      };
      this.procurementFont = {
        'color': '#FFFFFF',
      };
    },
    overChange() {
      this.sales = {
        'background-color': '',
      };
      this.procurement = {
        'background-color': '',
      };
      this.salesStyle = {
        'color': '#fdb913',
      };
      this.procurementStyle = {
        'color': '#f37321',
      };
      this.salesFont = {
        // 'color':'white',
      };
      this.procurementFont = {
        // 'color':'white',
      };
    },
    getDetails: function () {
        // this.$post(
        //     "/shoppingui/bidShopping/getTargetinfoByid",
        // ).then((data) => {
        //     if (data.code == 0) {

        //     }
        // })
    },
  },
}
</script>

<style scoped lang="scss">
@import "../assets/css/index.css";
@import "../assets/css/page/service.scss";
/deep/ .el-collapse-item__header{
  font-size: 20px;
  background: #f9deb6;
}
</style>
